<template>
  <navigation-layout>
    <centered-form-layout
      class="bg-custom-tertiary"
      :title="$t('clientSelector.projects')">
      <client-selector
        :key="idx"
        v-for="(client, idx) in clients"
        :link="`/${client.slug}?lang=${this.$i18n.locale}`">
        {{ client.categoryname }}
      </client-selector>
      <div
        v-if="!clients || clients.length === 0"
        class="text-custom-secondary empty-projects-message">
        {{ $t('clientSelector.no_projects_found') }}
      </div>
    </centered-form-layout>
  </navigation-layout>
</template>

<script>
  import {mapGetters} from 'vuex'
  import DesignMixin from '../mixin/design'

  export default {
    computed: {
      ...mapGetters({
        clientRootId: "config/clientRootCategory"
      })
    },
    data() {
      return {
        clients: [],
      }
    },
    /**
     * Uses this component to check, if the user has only one page (it redirects immediatly
     * to the target page). If the user has more than one category, this page is shown,
     * where you can choose your current client.
     * @return {Promise<NavigationFailure | void | undefined>}
     */
    async created() {
      // Applies the color/background image to the web page
      // This will be achieved in in rewrite css variables.
      let clientRootCategory = await this.applyDesign(this.clientRootId)
      this.$emit('updateUI')

      // Redirect to the webpage if the category is a folder and has an specific attribute.
      // Currently if the attribute 'template' is set it should used as 'project'
      if (clientRootCategory && this.isProject(clientRootCategory)) {
        return this.go('/' + this.$helper.slugify(clientRootCategory.categoryname) + '-' + clientRootCategory.id)
      }

      // List every child category as list on the screen
      const clientRootCategories = await this.$api.findCategories(this.clientRootId)
      this.clients = clientRootCategories.map(client => {
        return {
          ...client,
          slug: this.$helper.slugify(client.categoryname) + '-' + client.id
        }
      })
    },
    methods: {
      /**
       * Checks if the category is a project defined on the attribute which is set.
       * @param category
       * @return {boolean}
       */
      isProject(category) {
        return category.attributes?.find(attr => attr.name === 'Template')
          && category.categorytype === 'folder'
      }
    },
    inject: [
      '$api',
      '$helper',
    ],
    mixins: [
      DesignMixin,
    ]
  }
</script>

<style lang="scss" scoped>
  .empty-projects-message {
    @apply
      p-4
      text-center;
  }
</style>